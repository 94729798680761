//
// Environment
//


//
// Config values
//

$platform-name: 'eastmidlandscrossroadscaringfo';


//
// Colours
//

$brand-primary: #54565a;
$brand-secondary: #ff6b00;

$blue: #007da5;
$light-blue: #00c1de;
$yellow: #ffc629;
$green: #76bc21;

$donate-colour: $brand-secondary !default;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;





//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 960px;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1090px,
  xl: 1200px
);


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo: 'logo.svg';
$logo-width: 200px;
$logo-height: 120px;
$logo-absolute: true; // true if we want the logo to vertically intersect with the menu


//
// Typography
//

$font-family-base: 'Montserrat', sans-serif;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

$headings-margin-top: 1.5em !default; // This will only affect headings within post content


// Links
$link-hover-decoration: none;
$link-colour: $blue;

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em !default;
$btn-padding-y: .6em !default;
$btn-font-weight: $font-weight-bold;
$btn-border-radius: 5px;
$btn-border-width: 3px;
$btn-border-colour: transparent;

// Donate button
$donate-btn-colour: white;

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-gap: 2px;

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $brand-primary; // A colour, or 'brand'
$social-icons-hover-colour: brand; // A colour, or 'brand'

// Header specific
$social-icons-header-colour: $blue; // A colour, or 'brand'

// Footer specific social icons


//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-heading-font-size: $font-size-h4;

// Card text overlay

// Card text over
$card-text-over-image-vertical-text-position: top; // top, center or bottom
$card-text-over-image-darken-image-amount: 0;
$card-text-over-image-show-summary-on-hover: false;

// Card side by side

// Card hover state
$card-hover-image-opacity: .7 !default;
$card-hover-details-background-colour: transparent;
$card-hover-image-scale: 1.2;


//
// Menu admin
//
$menu-admin-enabled: false;


//
// Header
//


// Header content

// Tagline
$tagline-enabled: false;
// $tagline-colour: $blue;
// $tagline-above-cta-breakpoint: map-get($breakpoints, sm);
// $tagline-breakpoint: 0;

// Main call to action
$cta-btn-background-colour: $green;
$cta-btn-breakpoint: map-get($breakpoints, sm);
$cta-btn-gap: 10px;

// Search
$header-search-margin-right: 15px;

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;
$header-search-input-max-width: 180px;
$header-search-input-height: 39.19px;
$header-search-input-border-radius: $btn-border-radius;

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: rgba($grey, 0.85);

// Search when in nav
$nav-search-enabled: true;
$nav-search-input-margin-right: -43px;

// Social icons
$header-social-icons-margin-right: 15px;
// Colours set above (search for '$social-icons-header')


//
// Sticky header
//


//
// Navigation
//
$nav-background-colour: transparent;


// Top level items
$nav-top-level-item-padding: 10px 10px;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-colour: darken($brand-primary, 10%);
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-item-font-size: 0.875em;

// Submenus
$nav-submenu-background-colour: white;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);
$nav-submenu-item-font-size: 0.875em;

// Burger button
$burger-btn-bar-breakpoint: map-get($breakpoints, md); // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
$nav-normal-margin-bottom: $spacer;
$nav-normal-align-items: right; // left, center, right


//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//

$carousel-max-width: 100%;
$carousel-details-position-y: center; // top, center, bottom
$carousel-details-position-x: left !default; // left, center, right
$carousel-details-background-colour: rgba($light-blue, 0.85);
$carousel-details-margin-x: $spacer * 2;
$carousel-details-padding: $spacer * 2;
$carousel-heading-colour: white;
$carousel-heading-text-transform: uppercase;
$carousel-heading-font-size: 4em;
$carousel-details-border-radius: 15px;
$carousel-read-more-background-colour: transparent;
$carousel-read-more-border-colour: white;
$carousel-read-more-colour: white;
$carousel-summary-font-size: 1.125em;
$carousel-read-more-font-size: 0.899em;


// Carousel controls (left-right buttons)

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $light-blue;
$carousel-mobile-heading-colour: white;

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//

$home-intro-background-image: 'homeintro.svg';
$home-intro-background-colour: white;
$home-intro-background-image-opacity: 1;
$home-intro-content-max-width: 600px;
$home-intro-padding-y: $spacer * 5;
$home-intro-margin-top: 2rem;
$home-intro-colour: $brand-primary;
$home-intro-font-size: $font-size-h6;
$home-intro-line-height: 1.3;


//
// Home features
//

$home-features-padding-top: $spacer * 2;
$home-features-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations


//
// Impact stats
//
$impact-stats-background-colour: $blue;
$impact-stats-background-image: 'stats.jpg';
$impact-stats-background-image-opacity: 0.3;


// Heading

// Individual stat

// Figure

// Summary


//
// Home feeds
//

$home-feeds-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations

// Feeds title

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//

$footer-boxes-count: 3;
$footer-prefab: 4;
$footer-background-colour: $yellow;
$footer-link-colour: $body-colour;

// Footer admin links
$footer-admin-basket-link-enabled: false;

// Newsletter
$newsletter-background-colour: $yellow;
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 2;


//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts

// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//


// Donation amounts


//
// Quick giving panel
//

$quick-giving-background-colour: $brand-secondary;
$quick-giving-donation-amount-background-colour: rgba(white, 0.5);


//
// Home quick giving panel
//
$home-quick-giving-donation-amount-background-colour: $quick-giving-donation-amount-background-colour;
$home-quick-giving-heading-colour: white;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$shop-enabled: false; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Departments list

// Product-price

// Product post


//
// Subsite
//

$subsite-enabled: true;
$subsite-nav-top-level-item-background-colour: $grey-lighter;
$subsite-nav-top-level-item-hover-background-colour: darken($subsite-nav-top-level-item-background-colour, 10);


// Subsite logo
$subsite-logo: title; // Set to 'title' if you want to just use the subsite text title. If you use a logo image, it will act as a link back to the subsite's homepage

// Subsite nav


//
// Cookie consent
//
