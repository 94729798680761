// Add line under header on pages with no carousel
.page-no-banner .pageHeader {
    border-bottom: 1px solid rgba(black, 0.1);
}
// Additional para in home intro
.homeIntro {
    p {
        font-weight: normal;
    }
}
// remove space under burger menu
@media (max-width: map-get($breakpoints, md)) {
    .content.headerContent {
        margin-bottom: 0;
    }
}
// Tweak background image for responsiveness
.homeIntroWrapper {
    &:before {
        background-size: contain;
        background-position: bottom center;
    }
    @media (max-width: map-get($breakpoints, xl)) {
        padding-top: $spacer * 2;
        .homeIntro {
            width: 400px;
        }
    }
    @media (max-width: map-get($breakpoints, lg)) {
        padding-top: $spacer * 3;
        padding-bottom: $spacer * 3;
    }
    @media (max-width: map-get($breakpoints, md)) {
        .homeIntro {
            width: 320px;
        }
    }
    @media (max-width: map-get($breakpoints, sm)) {
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 6;
        .homeIntro {
            width: 250px;
        }
    }
}
// Change font
.carouselSlideHeading, .homeFeature  h2{
    font-family:'Cabin Sketch', sans-serif !important;
    font-weight: normal;
    margin-bottom: 0.25em;
}
// remove border for main CTA buttons
.mainCallToActionButtons,
.quickGivingPanel {
    .cta-button,
    .button {
        border: 0 !important;
    }
}
// Add vertical line to  main menu
.menuMain {
    .topLevel {
        .level1 {
            &:before {
                content:"";
                position: absolute;
                top: 0.75em;
                vertical-align: middle;
                display: inline-block;
                height: 1.125em;
                width: 1px;
                border-left: 1px solid $brand-secondary;
                @media (max-width: map-get($breakpoints, lg)) {
                    display:none;
                }
            }
            &:first-child:before {
                display: none;
            }
        }
    }
}
@media (max-width: map-get($breakpoints, lg)) {
    .menuMain {
        .topLevel {
            .level1 {
                font-size: 1.375em;
            }
        }
    }
}
// Tweak background image for responsiveness
.footerBox[class*="ewsletter"] {
    background-image: url($assets-path + 'newsletter.svg');
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: $spacer * 12;
    padding-top: $spacer * 7; // REMOVE THIS WHEN FORM IS SHOWN
    p {
        text-align: center;
        width: 550px;
        margin: $spacer * 2 auto 0 auto;
        a {
            font-weight: bold;
        }
    }
    @media (max-width: map-get($breakpoints, xl)) {
        padding-bottom: $spacer * 9;
        p {
            width: 400px;
        }
    }
    @media (max-width: map-get($breakpoints, lg)) {
        padding-bottom: $spacer * 7;
    }
    @media (max-width: map-get($breakpoints, md)) {
        padding-bottom: $spacer * 4;
        p {
            width: 300px;
        }
    }
    @media (max-width: map-get($breakpoints, sm)) {
        padding-bottom: $spacer * 2;
        p {
            width: 200px;
        }
    }
}
// Footer stuff
.footerBox {
    .footerLinks {
        display: flex;
        justify-content: flex-start;
        li {
            font-weight: bold;
            font-size: 0.875em;
            a {
                padding: 0.5em 0.9em;
            }
            &:first-child {
                a {
                    padding-left: 0;
                }
            }
        }
        @media (max-width: map-get($breakpoints, lg)) {
            justify-content: center;
        }
        @media (max-width: map-get($breakpoints, sm)) {
           display:block;
           text-align: center;
           li {
            &:first-child {
                a {
                    padding-left: 1em;
                }
            }
        }
        }
    }
    p {
        font-size: 0.875em;
    }
}
.footerBox.footerBox3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        width: 200px;
    }
    p {
        margin: 1em 0;
    }
    @media (max-width: map-get($breakpoints, lg)) {
        display: block;
        text-align: center;
    }
}
// heading weight
.homeFeatures .homeFeature h2 {
    font-weight: normal;
}
// SUbsite menu
.menuSub {
    li.level1 {
        margin-left: 5px;
        a {
            border-radius: 5px;
        }
    }
}
// Change colour for different slides in carousel
.carouselSlides {
    .carouselSlide:nth-child(2) {
        .carouselSlideDetail {
            background-color: rgba($green, 0.85);
        }
    }
    .carouselSlide:nth-child(3) {
        .carouselSlideDetail {
            background-color: rgba($brand-secondary, 0.85);
        }
    }
    .carouselSlide:nth-child(4) {
        .carouselSlideDetail {
            background-color: rgba($yellow, 0.85);
        }
    }
}

//Hide  newsletter form
.featureForm.Newsletter.Sign.upsForm {
    display: none;
}

// JK - 4 listed posts in a row on /Pages/Category/senior-and-key-staff
@include min-width(lg) {
  body.pages-category-senior-and-key-staff .listedPost {
    @include card-row(4);
  }
}
// Tagline
// .tagline {
//     text-align: right;
//     flex-grow: 0;
//     @media (max-width: map-get($breakpoints, md)) {
//         font-size: 1.5em;
//     }
//     &:before {
//         content:"\f10b\00a0";
//         font-size: 1.5em;
//         font-family: $icon-font-family;
//         vertical-align: middle;
//     }
// }
// .mainCallToAction {
//     flex-grow: 0 !important;
// }
// Remove border from burger button
.menuMainAlt {
    border: 0;
}
// Make tagline nicer below 375px
@media (max-width: 374px) {
    .mainLogo {
        height: 60px;
        width: 120px;
    }
    // .tagline {
    //     font-size: 16px;
    // }
}

// Quick giving in footerbox 1
// .footerBox.footerBox1 {
//     grid-column: 1 / span 16;
//     background-color: $yellow;
//     background-image: url($assets-path + 'newsletter.svg');
//     background-position: bottom center;
//     background-size: contain;
//     background-repeat: no-repeat;
//     .quickGivingPanel {
//         background-color: transparent;
//     }
//     h2 {
//         text-align: center;
//         margin-top: 3rem;
//         margin-bottom: 0;
//     }
// }

// Header content - layout
.pageHeader .headerContent {
    justify-content: flex-end;
}


// Footer - Layout
.pageFooter {
    padding-top: $spacer * 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: $container-max-width;
    .footerBox {
        margin: 2rem 15px 0;    
    }
}

@media (max-width: $nav-breakpoint) {
    .mainLogo { margin-right: auto; }
}

.mainCallToAction .cta-button[href*='contact'] {
    @include button (
        $btn-background-colour: $brand-secondary
    )
}

@media (max-width: $cta-btn-breakpoint) {
    .headerContent .mainCallToAction .cta-button[href*='contact'] {
        display: none;
    }
}